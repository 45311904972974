import React from 'react';
import loadable from '@loadable/component';

import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';

import css from './SectionBlog.module.css';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../../../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

import { H1 } from '../../../PageBuilder/Primitives/Heading';

const LandingPageBlogContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : null;

  if(!sectionsData) return null;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};


const SectionBlog = props => {

  const {
    pageAssetsData,
    inProgress,
    error,
  } = props

  return (
    <div className={css.root} id='clientsBlog' >
      <LandingPageBlogContent
        data={pageAssetsData}
        inProgress={inProgress}
        error={error}
      />
    </div>
  );
};

export default SectionBlog;

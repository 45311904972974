import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';

import IconDestress from './icons/IconDestress';
import IconEmpowerment from './icons/IconEmpowerment';
import IconLearnNew from './icons/IconLearnNew';
import IconLogo from './icons/IconLogo';
import IconWellness from './icons/IconWellness';

import css from './SectionBenefits.module.css';


const SectionBenefits = () => {

  const infoData = [
    {
      icon: <IconDestress />,
      title: <FormattedMessage id="SectionBenefits.title1" />,
      description: <FormattedMessage id="SectionBenefits.description1" />
    },
    {
      icon: <IconLearnNew />,
      title: <FormattedMessage id="SectionBenefits.title2" />,
      description: <FormattedMessage id="SectionBenefits.description2" />
    },
    {
      icon: <IconEmpowerment />,
      title: <FormattedMessage id="SectionBenefits.title3" />,
      description: <FormattedMessage id="SectionBenefits.description3" />
    },
    {
      icon: <IconWellness />,
      title: <FormattedMessage id="SectionBenefits.title4" />,
      description: <FormattedMessage id="SectionBenefits.description4" />
    },
  ]

  return (
    <div className={css.root}>

      <div className={css.heading}>
        <IconLogo />
        <H2 className={css.title}>
          <FormattedMessage id="SectionBenefits.mainTitle" />
        </H2>
      </div>

      <div className={css.info}>
        {infoData.map((item, i) => {
          return (
            <div className={css.infoItem} key={`infoItem-${i}`}>
              {item.icon}
              <h3 className={css.infoTitle}>
                {item.title}
              </h3>
              <p className={css.infoDescription}>
                {item.description}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default SectionBenefits;

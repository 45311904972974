import React, { useState, useEffect, useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { useConfiguration } from '../../../../context/configurationContext';
import { H2, Form, IconChevron } from '../../../../components';
import FieldFilteredSelect from '../../../../components/FieldFilteredSelect/FieldFilteredSelect';
import TreatmentCard from '../../../../components/TreatmentCard/TreatmentCard';
import { treatmentsData } from '../../../../components/TreatmentCard/treatments';

import css from './SectionSearch.module.css';
import searchIcon from './img/searchIcon.png'
import arrow from './img/arrow.png'

const CARDS_LIMIT_DESKTOP = 12;
const CARDS_LIMIT_MOBILE = 4;
const MODAL_BREAKPOINT = 768;

const SectionSearch = props => {
  const intl = useIntl();
  const { allResults } = props;

  const selectedTechniqueTypes = useMemo(() => {
    const allSelectedTechniqueTypes = allResults
      ?.map(t => t?.attributes?.publicData?.techniqueTypes)
      ?.flat()
      ?.filter(Boolean);
    return allSelectedTechniqueTypes ? Array.from(new Set(allSelectedTechniqueTypes)) : [];
  }, [allResults]);

  // if (!selectedTechniqueTypes.length) return null;

  const treatmentsDataFiltered = useMemo(
    () => treatmentsData.filter(item => selectedTechniqueTypes.includes(item.option)),
    [selectedTechniqueTypes]
  );

  const [inputTreatments, setInputTreatments] = useState(treatmentsDataFiltered);
  const [filteredTreatments, setFilteredTreatments] = useState(treatmentsDataFiltered);

  useEffect(() => {
    setInputTreatments(treatmentsDataFiltered);
    setFilteredTreatments(treatmentsDataFiltered);
  }, [treatmentsDataFiltered]);

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  const cardsCounter = isMobileLayout ? CARDS_LIMIT_MOBILE : CARDS_LIMIT_DESKTOP;

  return (
    <div className={css.root}>

      <FinalForm
        {...props}
        onSubmit={() => { }}
        render={formRenderProps => {
          const { values } = formRenderProps;

          const [visibleCount, setVisibleCount] = useState(cardsCounter);

          const showMore = () => {
            setVisibleCount(prevCount => prevCount + cardsCounter);
          };

          const showFewer = () => {
            setVisibleCount(prevCount => (prevCount - cardsCounter > cardsCounter ? prevCount - cardsCounter : cardsCounter));
          };

          useEffect(() => {
            if (inputTreatments && inputTreatments.length > 0) {
              const selectedOptions = inputTreatments.map(type => type.option);
              setFilteredTreatments(
                treatmentsData.filter(treatment =>
                  selectedOptions.includes(treatment.option)
                )
              );
            } else {
              setFilteredTreatments(treatmentsData);
            }
          }, [inputTreatments]);

          const config = useConfiguration();
          const listingFieldsConfig = config.listing.listingFields;
          const techniqueTypes = listingFieldsConfig.find(c => c.key === 'techniqueTypes').enumOptions;

          const filteredTreatmentsCategories = filteredTreatments?.map(c => c.option).join(',')

          return (
            <Form className={css.formRoot} onSubmit={(e) => {e.preventDefault()}}>
              <div className={css.formContainer}>

                <div className={css.titleHolder}>
                  <H2 className={css.title}>
                    <FormattedMessage id="SectionSearch.title" />
                  </H2>
                  <p className={css.info}>
                    <FormattedMessage id="SectionSearch.info" />
                  </p>
                </div>
                <FieldFilteredSelect
                  id="techniqueType"
                  name={`techniqueType`}
                  options={techniqueTypes}
                  placeholder={intl.formatMessage({ id: 'SectionSearch.searchPlaceholder' })}
                  noItemFoundPlaceholder={intl.formatMessage({ id: 'SectionSearch.noTehniqueFound' })}
                  icon={searchIcon}
                  setInputTreatments={setInputTreatments}
                  filteredTreatmentsCategories={filteredTreatmentsCategories}
                />
              </div>

              <div className={css.treatmentsRoot}>
                <div className={css.treatmentsContainer}>

                  {/* {treatmentsData.slice(0, visibleCount).map((treatment, index) => ( */}
                  {filteredTreatments.slice(0, visibleCount).map((treatment, index) => (
                    <TreatmentCard
                      key={index}
                      image={treatment.image}
                      label={treatment.label}
                      description={treatment.description}
                      option={treatment.option}
                    />
                  ))}
                </div>
                <div className={css.buttonContainer}>
                  {visibleCount > cardsCounter && (
                    <a onClick={showFewer} className={css.showButton} style={{ left: 0 }}>
                      Show less
                      <IconChevron direction='up' />
                    </a>
                  )}
                  {visibleCount < filteredTreatments.length && (
                    <a onClick={showMore} className={css.showButton} style={{ right: 0 }}>
                      Show more
                      <IconChevron />
                    </a>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SectionSearch;

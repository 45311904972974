import React from 'react';
import { NamedLink } from '../../components';

import css from './TreatmentCard.module.css';

const TreatmentCard = props => {
  const { image, option, label, description } = props;

  return (
    <NamedLink
      className={css.root}
      name="SearchPage"
      to={{ search: `?bounds=52.61334649%2C13.49248918%2C52.39936027%2C13.21996925&pub_techniqueTypes=${option}` }}
    >
      <img className={css.image} src={image} />
      <p className={css.title}>{label}</p>
      <p className={css.description}>{description}</p>
    </NamedLink>
  )
};

export default TreatmentCard;
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../SectionBenefits.module.css';

const IconWellness = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#F7FFD6"
        d="M26.983 21.935l-5.576 1.766c.246-.763-.049-1.847-.885-1.847H16c-.646 0-2.774-2.584-5.814-2.584H6.309v7.187c13.567 4.523 9.045 4.523 24.55-3.23a3.564 3.564 0 00-3.876-1.292z"
      ></path>
      <path
        fill="#DFFF60"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M1.787 17.977h3.876s.646 0 .646.646v9.044s0 .646-.646.646H1.787s-.646 0-.646-.646v-9.044s0-.646.646-.646z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M6.31 26.459c13.566 4.522 9.044 4.522 24.549-3.23a3.564 3.564 0 00-3.876-1.292l-5.577 1.766"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M6.31 19.27h3.876c3.04 0 5.168 2.584 5.814 2.584h4.522c1.292 0 1.292 2.584 0 2.584h-7.106"
      ></path>
      <path
        fill="#DFFF60"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M28.317 7.668a2.937 2.937 0 00-4.273-1.634A2.94 2.94 0 0019.9 2.76a2.943 2.943 0 00-1.608 3.274 2.94 2.94 0 10-1.775 5.47 2.942 2.942 0 104.651 3.378 2.94 2.94 0 104.652-3.379 2.942 2.942 0 002.497-3.835z"
      ></path>
      <path
        fill="#DFFF60"
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M18.88 9.991a2.289 2.289 0 104.577 0 2.289 2.289 0 00-4.577 0z"
      ></path>
    </svg>
  )
};

IconWellness.defaultProps = { className: null };

const { string } = PropTypes;

IconWellness.propTypes = {
  className: string,
  direction: string,
};

export default IconWellness;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../SectionBenefits.module.css';

const IconDestress = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#F7FFD6"
        d="M1.138 22.937c0 1.713 1.566 3.357 4.353 4.568 2.787 1.212 6.566 1.892 10.508 1.892 3.94 0 7.72-.68 10.507-1.892 2.787-1.211 4.353-2.855 4.353-4.568 0-1.713-1.566-3.357-4.353-4.568C23.72 17.157 19.94 16.477 16 16.477c-3.942 0-7.721.68-10.508 1.892-2.787 1.211-4.353 2.855-4.353 4.568z"
      ></path>
      <path
        fill="#DFFF60"
        d="M6.953 22.94c0 .685.953 1.342 2.65 1.827 1.696.485 3.997.757 6.396.757s4.7-.273 6.396-.757c1.697-.485 2.65-1.142 2.65-1.827 0-.686-.954-1.343-2.65-1.828-1.696-.484-3.997-.756-6.396-.756-2.4 0-4.7.272-6.397.756-1.696.485-2.65 1.142-2.65 1.828z"
      ></path>
      <path
        fill="#DFFF60"
        d="M28.474 10.12a12.776 12.776 0 00-4.323-.122c.027-1.66-.16-3.315-.559-4.927a.647.647 0 00-.859-.448 13.955 13.955 0 00-3.747 2.166l-.015.073a16.952 16.952 0 00-2.468-4.026.646.646 0 00-.997 0 16.933 16.933 0 00-2.454 3.981 13.956 13.956 0 00-3.788-2.196.646.646 0 00-.859.448 19.252 19.252 0 00-.558 4.925 12.804 12.804 0 00-4.323.13.646.646 0 00-.51.749c.293 1.597 1.325 5.96 4.239 8.23C10.87 21.904 16 20.417 16 20.417s5.133 1.487 8.746-1.316c2.914-2.262 3.946-6.626 4.237-8.226a.646.646 0 00-.51-.756z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M19.943 11.338c0 5.01-3.944 9.073-3.944 9.073s-3.944-4.062-3.944-9.073a14.281 14.281 0 013.446-8.502.645.645 0 01.996 0 14.282 14.282 0 013.446 8.502z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M18.98 6.787a13.954 13.954 0 013.748-2.166.646.646 0 01.86.449c.406 1.644 1.211 5.956-.407 9.456C21.141 18.952 16 20.41 16 20.41"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M24.14 9.996a12.807 12.807 0 014.329.121.646.646 0 01.509.75c-.29 1.6-1.323 5.964-4.237 8.226-3.618 2.803-8.748 1.316-8.748 1.316"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M13.142 6.889A13.915 13.915 0 009.266 4.62a.646.646 0 00-.86.449c-.407 1.646-1.212 5.956.406 9.456C10.858 18.952 16 20.41 16 20.41"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M7.89 10.001a12.784 12.784 0 00-4.365.117.646.646 0 00-.51.75c.292 1.6 1.324 5.963 4.238 8.232C10.865 21.896 16 20.41 16 20.41s0-.036-.013-.1M24.435 22.004a1.229 1.229 0 01.61.934c0 1.421-4.05 2.584-9.045 2.584s-9.045-1.163-9.045-2.584a.982.982 0 01.301-.664"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M28.84 19.684a4.227 4.227 0 012.019 3.254c0 3.568-6.653 6.46-14.859 6.46s-14.859-2.892-14.859-6.46a4.163 4.163 0 011.938-3.192M16 22.94v-2.585"
      ></path>
    </svg>
  )
};

IconDestress.defaultProps = { className: null };

const { string } = PropTypes;

IconDestress.propTypes = {
  className: string,
  direction: string,
};

export default IconDestress;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../SectionBenefits.module.css';

const IconEmpowerment = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_3338_67927)">
        <path
          fill="#fff"
          d="M6.648 25.352c-.914-.913-1.371-7.765.456-9.592L19.83 3.033a6.461 6.461 0 019.137 9.138L16.24 24.896c-1.827 1.829-8.679 1.37-9.592.456z"
        ></path>
        <path
          fill="#DFFF60"
          d="M28.967 3.031a6.46 6.46 0 010 9.138L16.24 24.895c-1.827 1.828-8.679 1.37-9.592.456l22.32-22.32z"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.99"
          d="M6.648 25.352c-.914-.913-1.371-7.765.456-9.592L19.83 3.033a6.461 6.461 0 019.137 9.138L16.24 24.896c-1.827 1.829-8.679 1.37-9.592.456zM28.967 3.035L1.141 30.86"
        ></path>
        <path
          stroke="#21262A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.99"
          d="M21.723 19.416c-1.827 1.827-8.68 1.366-9.6.462-.918-.905-1.369-7.766.458-9.593M27.204 13.933c-1.829 1.829-8.68 1.371-9.594.458-.914-.914-1.371-7.766.457-9.594"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3338_67927">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
};

IconEmpowerment.defaultProps = { className: null };

const { string } = PropTypes;

IconEmpowerment.propTypes = {
  className: string,
  direction: string,
};

export default IconEmpowerment;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../SectionBenefits.module.css';

const IconLearnNew = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#F7FFD6"
        d="M3.08 23.741c0 1.631 1.292 3.195 3.594 4.348 2.302 1.152 5.424 1.8 8.68 1.8 3.255 0 6.378-.648 8.68-1.8 2.302-1.153 3.595-2.717 3.595-4.348 0-1.63-1.294-3.194-3.596-4.347-2.301-1.152-5.424-1.8-8.679-1.8s-6.378.647-8.68 1.8c-2.302 1.153-3.595 2.717-3.595 4.348z"
      ></path>
      <path
        fill="#DFFF60"
        d="M8.355 18.692c1.69 2.625 6.372 4.514 11.887 4.514 2.36.02 4.707-.355 6.942-1.112-1.44-2.595-6.189-4.5-11.828-4.5a21.586 21.586 0 00-7.001 1.098z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M8.248 15.353c0 .722.267 1.436.786 2.103.52.666 1.281 1.272 2.241 1.782.96.51 2.1.915 3.354 1.191s2.598.418 3.955.418c1.358 0 2.702-.142 3.956-.418s2.393-.68 3.353-1.19c.96-.511 1.721-1.117 2.241-1.783.52-.667.787-1.381.787-2.103 0-.721-.267-1.436-.787-2.102-.52-.667-1.28-1.272-2.24-1.782-.96-.51-2.1-.915-3.354-1.191a18.527 18.527 0 00-3.956-.419c-1.357 0-2.701.143-3.955.419-1.255.276-2.394.68-3.354 1.19-.96.51-1.721 1.116-2.24 1.783-.52.666-.787 1.38-.787 2.102z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855z"
      ></path>
      <path
        fill="#DFFF60"
        d="M20.092 13.793a15.355 15.355 0 006.977-1.574 14.456 14.456 0 00-8.485-2.36 16.28 16.28 0 00-6.956 1.43 14.203 14.203 0 008.464 2.504z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855z"
      ></path>
      <path
        fill="#fff"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855z"
      ></path>
      <path
        fill="#F7FFD6"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855z"
      ></path>
      <path
        fill="#fff"
        d="M10.832 6.956c0 .274.036.547.108.811A10.8 10.8 0 0016 8.941c4.28 0 7.753-2.167 7.753-4.846 0-.273-.037-.546-.109-.81a10.781 10.781 0 00-5.06-1.174c-4.28 0-7.752 2.169-7.752 4.845z"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M26.42 11.775a4.458 4.458 0 012.5 3.58c0 3.034-4.627 5.491-10.336 5.491-5.708 0-10.336-2.454-10.336-5.49 0-2.033 2.077-3.809 5.168-4.758"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855zM27.072 21.92c.356.546.549 1.182.557 1.833 0 3.39-5.495 6.138-12.275 6.138S3.079 27.144 3.079 23.753c0-2.385 2.721-4.454 6.698-5.468"
      ></path>
      <path
        stroke="#21262A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.99"
        d="M10.832 6.955c0 .636.2 1.266.59 1.854.39.588.96 1.122 1.68 1.572.72.45 1.575.807 2.515 1.05.941.244 1.95.369 2.967.369 1.018 0 2.026-.125 2.967-.369.94-.243 1.795-.6 2.515-1.05.72-.45 1.29-.984 1.68-1.572.39-.588.59-1.218.59-1.854 0-.637-.2-1.267-.59-1.855-.39-.587-.96-1.122-1.68-1.571-.72-.45-1.575-.807-2.515-1.05a11.906 11.906 0 00-2.967-.37c-1.018 0-2.026.126-2.967.37-.94.243-1.795.6-2.515 1.05-.72.45-1.29.984-1.68 1.571-.39.588-.59 1.218-.59 1.855z"
      ></path>
    </svg>
  )
};

IconLearnNew.defaultProps = { className: null };

const { string } = PropTypes;

IconLearnNew.propTypes = {
  className: string,
  direction: string,
};

export default IconLearnNew;
